export class FileExtensions {
    public static allowableExtension = [
      { extension: 'jpg', type: 'image' },
      { extension: 'gif', type: 'image' },
      { extension: 'png', type: 'image' },
      { extension: 'jpeg', type: 'image' },
      { extension: 'bmp', type: 'image' },
      { extension: '3gp', type: 'video' },
      { extension: 'mp4', type: 'video' },
      { extension: 'm4a', type: 'video' },
      { extension: 'aac', type: 'video' },
      { extension: 'mkv', type: 'video' },
      { extension: 'wav', type: 'video' },
      { extension: 'ogg', type: 'audio' },
      { extension: 'mp3', type: 'audio' },
      { extension: 'wav', type: 'audio' },
      { extension: 'wma', type: 'audio' },
      { extension: 'doc', type: 'document' },
      { extension: 'docx', type: 'document' },
      { extension: 'xls', type: 'excel' },
      { extension: 'xlsx', type: 'excel' },
      { extension: 'pdf', type: 'pdf' }
    ];
  
    public static getExtensionType(extension) {
      const data = this.allowableExtension.find(Data => {
        return Data.extension.toLowerCase() === extension.toLowerCase();
      });
      if (data) {
        return data.type;
      } else {
        return '';
      }
    }
  }
  